.ui.feed > .event > .content .summary > .date {
    display: inline-block;
    float: none;
    font-weight: normal;
    font-size: 0.9em;
    font-style: normal;
    margin: 0em 0em 0em 0.5em;
    padding: 0em;
    color: darkblue;
  }

  .ui.feed > .event > .content .summary {
    margin: 0em;
    font-size: 1.4em;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.87);
  }

  .ui.card .meta {
    font-size: 1em;
    color: green;
    font-size: 1.5em;
  }

  .tab_wrapped{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .segmentTopMargin {
    margin-top: "10em"
  }


  @media only screen and (max-width: 768px) {
   .segmentTopMargin {
       margin-top: "12em"
   }
  }


  /*Fix the header to the top*/
.freeze-head-and-col tr:last-of-type th{
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
}

/*Fix the first column  to the left*/
.freeze-head-and-col td:first-of-type{
  position: -webkit-sticky;
  position: sticky;
  background-color:lightblue;
  left: 0px;
  font-weight: bold;
  z-index: 10;
}

.userDropDown {
  opacity: 1 !important;
}

.hideZIndex{
  z-index: -1;
}

.ui.menu {
}


[data-tooltip]::after {
  white-space: pre;
}

.listItemPadding{
  padding-bottom: 50px;
  margin-bottom: 50px;
}

.mainMenu {
  z-index: 102;
}

.ui.scrolling.dropdown .menu,
.ui.dropdown .scrolling.menu {
  max-height: 200px !important;
}

footer{
  margin-top: 0rem;
  padding: 0rem;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}

.labelMarginLeft{
  margin-left: 10;
}


.ui.horizontal.list:not(.celled) > .item:first-child {
  margin-left: 1.8em !important;
  padding-left: 0em !important;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

